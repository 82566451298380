<template>
  <div>
    <div class="card-toolbar mb-5">
      <!--      v-if="$can('apply_commission.create')"-->
      <router-link  to="/agents/apply-commission/create" class="btn btn-primary font-weight-bolder">
        <v-icon>mdi-plus</v-icon>
        {{ $t('apply_commission.add_apply_commission') }}
      </router-link>
      <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5">
        <span><i class="la la-filter"></i> <span>{{ $t('advanced_filter') }}</span></span>
      </button>
    </div>

    <div class="card card-custom mb-5" v-if="showAdvancedSearch">
      <div class="card-body">
        <div class="m-form m-form--fit m--margin-bottom-20">
          <div class="row">

            <div class="form-group col-md-6">
              <label for="name">{{ $t('apply_commission.agent_name') }}</label>
              <multiselect
                  v-model="agent"
                  :placeholder="$t('apply_commission.agent_name')"
                  label="name"
                  track-by="id"
                  :options="agents"
                  :multiple="false"
                  :taggable="false"
                  :show-labels="false"
                  :show-no-options="false"
                  :show-no-results="false"
                  :internal-search="false"
              >
              </multiselect>
            </div>
            <div class="form-group col-md-6">
              <label for="from_date">{{ $t('apply_commission.from_date') }}</label>
              <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
            </div>
            <div class="form-group col-md-6">
              <label for="to_date">{{ $t('apply_commission.to_date') }}</label>
              <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="f_status">{{ $t('status') }}</label>
              <select name="" id="f_status" v-model="filters.status" type="text" class="custom-select">
                <option value="">{{ $t('all') }}</option>
                <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.title }}</option>
              </select>
            </div>
            <div class="form-group d-inline-flex col-md-6">
              <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                            <span><i class="fas fa-search"></i>
                                <span>{{ $t('search') }}</span>
                            </span>
              </button>
              <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                            <span><i class="fas fa-trash-restore"></i>
                                <span>{{ $t('reset_search') }}</span>
                            </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--begin::User-->
    <div class="card card-custom">
      <div class="card-body">
        <div class="d-flex justify-content-end mb-5">
          <custom-export-data :data-list="dataList" :route="mainRoute" :filters="filters" :fields="json_fields"
                              :file-name="$t('MENU.apply_commission')"></custom-export-data>
        </div>

        <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">
          <template slot="actions" slot-scope="props">
            <div v-if="props.row.status != 2">
              <v-btn v-b-tooltip.hover v-if="props.row.status == 1" :title="$t('edit')"  icon color="pink" :to="`/agents/apply-commission/edit/${props.row.id}`">
                <v-icon small class="mr-2 text-info">mdi-pencil</v-icon>
              </v-btn>
              <v-icon small class="text-danger" v-b-tooltip.hover :title="$t('delete')" @click="deleteItem(props.row)">mdi-delete </v-icon>
            </div>
<!--            v-if="$can('agents.update')"-->
              <button v-if="props.row.status == 1" class="btn btn-dark btn-sm" @click="showModal(props.row)">{{$t('apply_commission.pay')}}</button>
              <span v-else-if="props.row.status == 2" style="color:#0C6F58;">{{$t('apply_commission.paid')}}</span>
          </template>
        </v-server-table>

      </div>
    </div>
    <!--end::User-->

    <b-modal ref="modal" hide-footer :title="$t('apply_commission.pay_for_agent')">
      <div class="form-group row">
        <div class="col-md-12 mb-5">
          <label>{{ $t('apply_commission.payment_method') }}<span class="text-danger">*</span></label>
          <select name="" id="f_status" v-model="data_pay.payment_method" class="custom-select"  :class="validation && validation.payment_method ? 'is-invalid' : ''">
            <option v-for="(row, index) in payment_method_list" :value="row.id" :key="index">{{ row.title }}</option>
          </select>
          <span v-if="validation && validation.payment_method" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.payment_method[0] }}
                </span>
        </div>
        <div class="col-md-12 mb-5">
          <label>{{ $t('apply_commission.payment_notes') }}</label>
          <textarea type="text" v-model="data_pay.payment_notes" class="form-control" :class="validation && validation.payment_notes ? 'is-invalid' : ''"></textarea>
          <span v-if="validation && validation.payment_notes" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.payment_notes[0] }}
                </span>
        </div>
      </div>

      <div class="d-flex justify-content-end">
        <b-button class="mt-2 mr-1" variant="secondary" @click="hideModal">{{$t('close')}}</b-button>
        <b-button class="ml-2 mt-2 mr-2" variant="primary" @click="save">{{$t('apply_commission.pay')}}</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "index-apply-commission",
  components: {},
  data() {
    return {
      mainRoute: 'agents/apply_commissions',
      subMainRoute: 'agents/apply_commission',
      mainRouteDependency: 'base/dependency',
      showAdvancedSearch: false,
      dataList: [],

      filter: {
        sortBy: 'id',
      },
      filters: {
        agent_id: null,
        from_date: null,
        to_date: null,
        status: null,
      },
      columns: ['agent_name', 'from_date', 'to_date', 'amount', 'status_name', 'actions'],
      data: [],

      agent: null,
      agents: [],

      customer: null,
      customers: [],

      status_list: [],

      data_pay:{
        id: null,
        agent_id: null,
        payment_method: null,
        payment_notes: null,
      },
      validation: null,
      payment_method_list: [

        {id: 1, title: this.$t('payment_methods.credit_card')},
        {id: 2, title: this.$t('payment_methods.paypal')},
        {id: 3, title: this.$t('payment_methods.cash')},
        {id: 4, title: this.$t('payment_methods.bank_transfer')},
      ],
    }
  },
  computed: {
    json_fields: function () {
      let fields = {};
      fields[this.$t('apply_commission.agent_name')] = 'agent_name';
      fields[this.$t('apply_commission.from_date')] = 'from_date';
      fields[this.$t('apply_commission.to_date')] = 'to_date';
      fields[this.$t('apply_commission.amount')] = 'amount';
      fields[this.$t('status')] = 'status_name';

      return fields;
    },
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          agent_name: that.$t('apply_commission.agent_name'),
          from_date: that.$t('apply_commission.from_date'),
          to_date: that.$t('apply_commission.to_date'),
          amount: that.$t('apply_commission.amount'),
          status_name: that.$t('status'),
          actions: that.$t('actions'),
        },
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',

        }],
        orderBy: {'column': 'created_at'},

        alwaysShowPerPageSelect: false,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: data.ascending,
            byColumn: data.byColumn,
            limit: data.perPage,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query(that.mainRoute, {..._params});

        },
        responseAdapter(resp) {
          that.dataList = resp.data.data.data;
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        }
      }

    },
  },
  watch: {
    "agent": function (val) {
      if (val && val.id) {
        this.filters.agent_id = val.id;
      } else {
        this.filters.agent_id = null;
      }
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.apply_commission")}]);
    this.getStatus();
    this.getAgents();
  },
  methods: {
    openAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },
    doFilter() {
      this.$refs.table.refresh();
    },
    resetFilter() {
      this.filters.agent_id = null;
      this.filters.from_date = null;
      this.filters.to_date = null;
      this.filters.status = null;
      this.agent = null;
      this.customer = null;

      this.$refs.table.refresh();
    },

    getFetch() {
      this.$refs.table.refresh();
    },

    actionDelete(item) {
      ApiService.delete(this.mainRoute+`/${item.id}`).then(response => {
        this.getFetch();
        this.$successAlert(response.data.message)
      }).catch((error) => {
        this.$errorAlert(error);
      })
    },

    deleteItem(item) {
      this.$confirmAlert('', this.actionDelete, item);
    },

    getAgents() {
        ApiService.get(this.mainRouteDependency + "/agents").then((response) => {
          this.agents = response.data.data;
        });
    },
    getStatus() {
      ApiService.get(this.mainRouteDependency + "/status").then((response) => {
        this.status_list = response.data.data;
      });
    },
    showModal(data) {
      this.data_pay.id = data.id;
      this.data_pay.agent_id = data.agent_id;
      this.data_pay.payment_method = null;
      this.data_pay.payment_notes = null;

      this.$refs['modal'].show();
    },
    hideModal() {
      this.$refs['modal'].hide();
      this.data_pay.id = null;
      this.data_pay.agent_id = null;
      this.data_pay.payment_method = null;
      this.data_pay.payment_notes = null;
    },
    save() {
      ApiService.patch(this.subMainRoute + '/pay/' + this.data_pay.id, {
        ...this.data_pay
      }).then(response => {
        this.hideModal();
        this.$refs.table.refresh();
        this.$successAlert(response.data.message);
      }).catch(error => {
        this.$errorAlert(error);
      });
    },
  },
};
</script>
